import config from "./settings";
import {
  commonDelete,
  commonGet,
  commonGetBlob,
  commonPut,
  commonPutBlob,
} from "./common";

const getField = (field) => (token, providerId, userId) => {
  const url = `${config.url}/auth/providers/${providerId}/hr/${userId}${field}`;
  return commonGet(token, url);
};
const updateField = (field) => (token, providerId, userId, data) => {
  const url = `${config.url}/auth/providers/${providerId}/hr/${userId}${field}`;
  return commonPut(token, url, data);
};
const getUpdateField = ({ name, field }) => ({
  [`get${name}`]: getField(field),
  [`update${name}`]: updateField(field),
});
const HR = {
  ...getUpdateField({ name: "PersonalDetails", field: "" }),
  ...getUpdateField({ name: "NextOfKin", field: "/nextofkin" }),
  ...getUpdateField({ name: "Address", field: "/address" }),
  ...getUpdateField({ name: "WorkingType", field: "/workingtype" }),
  ...getUpdateField({ name: "BankDetails", field: "/bankdetails" }),
  ...getUpdateField({ name: "Legal", field: "/legal" }),
  ...getUpdateField({ name: "WorkingHours", field: "/workingHours" }),
  getHolidaySummary(token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/hr/${userId}/holidaySummary`;
    return commonGet(token, url);
  },
  getLegalList(token, providerId) {
    const url = `${config.url}/auth/providers/${providerId}/hrlegal`;
    return commonGet(token, url);
  },
  getLearningDue(token, providerId) {
    const url = `${config.url}/auth/providers/${providerId}/hrlearningdue`;
    return commonGet(token, url);
  },
  getHolidaysUpcoming(token, providerId) {
    const url = `${config.url}/auth/providers/${providerId}/hrholidaysupcoming`;
    return commonGet(token, url);
  },
  getAvatar(token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/hr/${userId}/avatar`;
    return fetch(url, {
      method: "GET",
      headers: {
        ...config.configHeaders,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          return null;
        }
        if (response.status !== 200) {
          return response;
        }

        return response.text();
      })
      .then((text) => {
        return { response: text };
      });
    // .catch((error) => ({ error }))
  },

  updateAvatar(token, providerId, userId, data) {
    const url = `${config.url}/auth/providers/${providerId}/hr/${userId}/avatar.jpg`;
    return commonPutBlob(token, url, data);
  },

  deleteAvatar(token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/hr/${userId}/avatar.jpg`;
    return commonDelete(token, url);
  },
  downloadLegalDocsFile(token, providerId, userId, filename) {
    const url = `${config.url}/auth/providers/${providerId}/hr/${userId}/legal/files/${filename}`;
    return commonGetBlob(token, url);
  },
  downloadDocsFile(token, providerId, userId, filename) {
    const url = `${config.url}/auth/providers/${providerId}/hr/${userId}/files/${filename}`;
    return commonGetBlob(token, url);
  },
  downloadConditionFile(token, providerId, userId, conditionId, filename) {
    const url = `${config.url}/auth/providers/${providerId}/hr/${userId}/conditions/${conditionId}/files/${filename}`;
    return commonGetBlob(token, url);
  },
  //   static downloadConditionFile (
  //     token,
  //     providerId,
  //     userId,
  //     conditionId,
  //     filename
  //   ) {
  //   const url = `${config.url
  //   }/auth/providers/${providerId}/serviceusers/${userId}/conditions/${conditionId}/files/${filename}`
  //   return commonGetBlob(token, url)
  // }
};

export default HR;
