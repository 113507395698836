import actions from "./actions";
import { parseError } from "../../../helpers/errors";

const initState = {
  authPending: false,
  loggedIn: false,
  loginError: false,
  authToken: null,
  refreshToken: null,
  tokenIsValid: null,
  pendingRefreshingToken: null,
  tokenRefreshCount: 0,
  pwResetLinkPending: false,
  pwResetLinkError: false,
  resetPasswordPending: false,
  resetPasswordError: false,
  samlAuth: null,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_AUTH_PENDING:
      return {
        ...state,
        authPending: true,
      };
    case actions.LOGIN_REQUEST:
    case actions.LOGIN_O365_REQUEST:
      return state;
    case actions.LOGIN_SUCCESS:
    case actions.LOGIN_O365_SUCCESS:
      return {
        ...state,
        authPending: false,
        loggedIn: true,
        loginError: false,
        authToken: action.authToken,
        refreshToken: action.refreshToken,
      };
    case actions.LOGIN_ERROR:
    case actions.LOGIN_O365_ERROR:
      return {
        ...state,
        authPending: false,
        loggedIn: false,
        loginError: action.loginError,
      };
    case actions.LOGOUT:
      return {
        ...state,
        authToken: false,
        refreshToken: false,
        loggedIn: false,
      };
    case actions.PASSWORD_RESET_LINK_REQUEST:
      return {
        ...state,
        pwResetLinkPending: true,
        pwResetLinkError: false,
      };
    case actions.PASSWORD_RESET_LINK_SUCCESS:
      return {
        ...state,
        pwResetLinkPending: false,
        pwResetLinkError: false,
      };
    case actions.PASSWORD_RESET_LINK_ERROR:
      return {
        ...state,
        pwResetLinkPending: false,
        pwResetLinkError: parseError(action.error),
      };
    case actions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordPending: true,
        resetPasswordError: false,
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordError: false,
      };
    case actions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordError: parseError(action.error),
      };
    case actions.INVALID_TOKEN:
      return {
        ...state,
        tokenIsValid: false,
      };
    case actions.TOKEN_REFRESH_REQUEST:
      return {
        ...state,
        pendingRefreshingToken: true,
        tokenIsValid: false,
        tokenRefreshCount: state.tokenRefreshCount + 1,
      };
    case actions.TOKEN_REFRESH_SUCCESS:
      return {
        ...state,
        pendingRefreshingToken: null,
        tokenIsValid: true,
      };
    case actions.TOKEN_REFRESH_ERROR:
      return {
        ...state,
        pendingRefreshingToken: null,
        tokenIsValid: false,
        authToken: false,
      };
    case actions.TOKEN_REFRESH_RESET:
      return {
        ...state,
        tokenRefreshCount: 0,
      };
    case actions.SAVE_APP_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
      };
    case actions.AUTH_EXCHANGE_SAML_TOKEN_REQUEST:
      return {
        ...state,
        samlAuth: {
          ...state.samlAuth,
          loading: true,
        },
      };
    case actions.AUTH_EXCHANGE_SAML_TOKEN_SUCCESS:
      // console.log(action);
      return {
        ...state,
        samlAuth: {
          ...state.samlAuth,
          data: action.payload,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    case actions.AUTH_EXCHANGE_SAML_TOKEN_ERROR:
      return {
        ...state,
        samlAuth: {
          ...state.samlAuth,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
