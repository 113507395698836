import { all, call, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import crudSagas from "../../crud/sagas";
import { getAuthToken } from "../../../../helpers/tokens";
import Api from "../../../../api";

import FileSaver from "file-saver";

const apiPath = "providers/:providerId/serviceusers/:userId/documents/files";
const propName = "id";
export function* downloadRequest() {
  yield takeEvery(
    actions.SERVICEUSER_DOCUMENT_DOC_DOWNLOAD_REQUEST,
    function* ({ payload }) {
      const authToken = yield getAuthToken();
      if (authToken && authToken.token) {
        const { response, error } = yield call(
          Api.ServiceUser.downloadServiceUserDocsFile,
          authToken.token,
          payload.providerId,
          payload.userId,
          payload.id
        );
        if (!response) {
          yield put(actions.getError(error));
        }

        FileSaver.saveAs(response, decodeURI(payload.id));

        // Free up the memory
        URL.revokeObjectURL(response);
      }
    }
  );
}

export default function* devSaga() {
  yield all([
    crudSagas(actions, Api.Crud, apiPath, propName),
    downloadRequest(),
  ]);
}
