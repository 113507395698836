import { all, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import { history } from "../../configureStore";
import {
  FIELD_AVAILABILITY_STATUS,
  FIELD_DATE_RANGE_PICKER,
  FIELD_LOCATIONS,
  FIELD_SERVICEUSERS,
  FIELD_STAFFUSERS,
  FIELD_TITLE,
  FIELD_TYPE,
  FIELD_VISIT_STATUS,
} from "../../../containers/App/Rostering/Filters/Filters";
import { dateFormatISO8601 } from "../../../helpers/utility";
import { store } from "../../../BaseWrapper";
import schedulingActions from "../scheduling/actions";
import { defineGetSagaProvider } from "../../sagasHelper";
import Api from "../../../api";
import roundsActions from "../rounds/actions";

function* setFilters() {
  yield takeEvery(
    [
      actions.SET_FILTERS,
      actions.LOAD_FILTERS_FROM_QUERY_STRING,
      actions.SET_SCHEDULE_PAGE,
    ],
    function* () {
      const state = store.getState();
      const { filters, schedule } = state.Rostering;

      const parts = [];
      if (
        filters[FIELD_DATE_RANGE_PICKER] &&
        filters[FIELD_DATE_RANGE_PICKER].length === 2
      ) {
        parts.push(
          `timeFrom=${filters[FIELD_DATE_RANGE_PICKER][0]
            .utc()
            .format(dateFormatISO8601)}`
        );
        parts.push(
          `timeTo=${filters[FIELD_DATE_RANGE_PICKER][1]
            .utc()
            .format(dateFormatISO8601)}`
        );
      }

      if (
        filters[FIELD_SERVICEUSERS] &&
        filters[FIELD_SERVICEUSERS].length > 0
      ) {
        parts.push(`serviceUserIds=${filters[FIELD_SERVICEUSERS].join(",")}`);
      }

      if (filters[FIELD_STAFFUSERS] && filters[FIELD_STAFFUSERS].length > 0) {
        parts.push(`staffUserIds=${filters[FIELD_STAFFUSERS].join(",")}`);
      }

      if (filters[FIELD_LOCATIONS] && filters[FIELD_LOCATIONS].length > 0) {
        parts.push(`locationIds=${filters[FIELD_LOCATIONS].join(",")}`);
      }

      if (filters[FIELD_TITLE]) {
        parts.push(`title=${filters[FIELD_TITLE]}`);
      }

      if (filters[FIELD_TYPE] && filters[FIELD_TYPE].length > 0) {
        parts.push(`visitTypes=${filters[FIELD_TYPE].join(",")}`);
      }

      if (
        filters[FIELD_VISIT_STATUS] &&
        filters[FIELD_VISIT_STATUS].length > 0
      ) {
        parts.push(`visitStatuses=${filters[FIELD_VISIT_STATUS].join(",")}`);
      }

      if (
        filters[FIELD_AVAILABILITY_STATUS] &&
        filters[FIELD_AVAILABILITY_STATUS].length > 0
      ) {
        parts.push(
          `availabilityStatuses=${filters[FIELD_AVAILABILITY_STATUS].join(",")}`
        );
      }

      parts.push(`sOffset=${schedule.offset}`);
      // parts.push(`pageSize=${schedule.pageSize}`)

      if (!filters[FIELD_LOCATIONS] || filters[FIELD_LOCATIONS].length === 0) {
        return;
      }

      yield put(actions.load());

      history.push({
        search: "?" + parts.join("&"),
      });
    }
  );
}

const unifiedVisitsQueryFromFilters = (filters) => {
  return {
    timeFrom: filters[FIELD_DATE_RANGE_PICKER][0].utc(),
    timeTo: filters[FIELD_DATE_RANGE_PICKER][1].utc().clone().add({ d: 1 }),
    serviceUserIds: filters[FIELD_SERVICEUSERS] && filters[FIELD_SERVICEUSERS],
    careWorkerIds: filters[FIELD_STAFFUSERS] && filters[FIELD_STAFFUSERS],
    locationIds: filters[FIELD_LOCATIONS] && filters[FIELD_LOCATIONS],
    visitTypes: filters[FIELD_TYPE] && filters[FIELD_TYPE],
    statuses: filters[FIELD_VISIT_STATUS] && filters[FIELD_VISIT_STATUS],
    title: filters[FIELD_TITLE] && filters[FIELD_TITLE],
  };
};
//
// const unifiedAvailabilityQueryFromFilters = (filters) => {
//   return {
//     timeFrom: filters[FIELD_DATE_RANGE_PICKER][0].utc(),
//     timeTo: filters[FIELD_DATE_RANGE_PICKER][1].utc().clone().add({d: 1}),
//     serviceUserIds: filters[FIELD_SERVICEUSERS] && filters[FIELD_SERVICEUSERS],
//     staffUserIds: filters[FIELD_STAFFUSERS] && filters[FIELD_STAFFUSERS],
//     locationIds: filters[FIELD_LOCATIONS] && filters[FIELD_LOCATIONS],
//     visitTypes: filters[FIELD_TYPE] && filters[FIELD_TYPE],
//     statuses: filters[FIELD_AVAILABILITY_STATUS] && filters[FIELD_AVAILABILITY_STATUS],
//     title: filters[FIELD_TITLE] && filters[FIELD_TITLE],
//   }
// }
//
// const unifiedRoundsQueryFromFilters = (filters) => {
//   return {
//     timeFrom: filters.timeFrom ? filters.timeFrom.startOf('minute') : '',
//     timeTo: filters.timeTo ? filters.timeTo.startOf('minute') : '',
//     dateFrom: filters[FIELD_DATE_RANGE_PICKER][0],
//     dateTo: filters[FIELD_DATE_RANGE_PICKER][1],
//     serviceUserIds: filters[FIELD_SERVICEUSERS] && filters[FIELD_SERVICEUSERS],
//     careWorkerIds: filters[FIELD_STAFFUSERS] && filters[FIELD_STAFFUSERS],
//     locationIds: filters[FIELD_LOCATIONS] && filters[FIELD_LOCATIONS],
//     visitTypes: filters[FIELD_TYPE] && filters[FIELD_TYPE],
//     statuses: filters[FIELD_VISIT_STATUS] && filters[FIELD_VISIT_STATUS],
//     title: filters[FIELD_TITLE] && filters[FIELD_TITLE],
//   }
// }

const unifiedRosteringQueryFromFilters = (filters) => {
  return {
    timeFrom: filters.timeFrom ? filters.timeFrom.startOf("minute") : "",
    timeTo: filters.timeTo ? filters.timeTo.startOf("minute") : "",
    dateFrom: filters[FIELD_DATE_RANGE_PICKER][0].utc(),
    dateTo: filters[FIELD_DATE_RANGE_PICKER][1].utc().clone().add({ d: 1 }),
    serviceUserIds: filters[FIELD_SERVICEUSERS] && filters[FIELD_SERVICEUSERS],
    staffUserIds: filters[FIELD_STAFFUSERS] && filters[FIELD_STAFFUSERS],
    locationIds: filters[FIELD_LOCATIONS] && filters[FIELD_LOCATIONS],
    title: filters[FIELD_TITLE] && filters[FIELD_TITLE],

    visitTypes: filters[FIELD_TYPE] && filters[FIELD_TYPE],
    visitStatuses: filters[FIELD_VISIT_STATUS] && filters[FIELD_VISIT_STATUS],
    availabilityStatuses:
      filters[FIELD_AVAILABILITY_STATUS] && filters[FIELD_AVAILABILITY_STATUS],
    roundStatuses: filters[FIELD_VISIT_STATUS] && filters[FIELD_VISIT_STATUS],
  };
};

function* load() {
  yield takeEvery([actions.LOAD], function* () {
    const state = store.getState();
    const { filters, schedule } = state.Rostering;
    const provider = state.AuthUser.provider;

    const unifiedRosterQuery = {
      ...unifiedRosteringQueryFromFilters(filters),
      offset: schedule.offset && schedule.offset,
      pageSize: schedule.pageSize,
    };
    yield put(
      actions.getUnifiedRosterRequest(
        {
          providerId: provider.providerId,
        },
        unifiedRosterQuery
      )
    );
    const data = filters;
    const qs =
      `locationIds=${data.locations ? data.locations.join() : ""}` +
      `&dateFrom=${
        data.dateRange ? data.dateRange[0].format("YYYY-MM-DD") : ""
      }` +
      `&dateTo=${
        data.dateRange ? data.dateRange[1].format("YYYY-MM-DD") : ""
      }` +
      `&title=${data.title ? data.title : ""}` +
      `&offset=${data.offset ? data.offset : 0}` +
      `&pageSize=${data.pageSize ? data.pageSize : ""}`;

    // console.log("QS", qs);
    yield put(
      roundsActions.getList(
        {
          providerId: provider.providerId,
        },
        undefined,
        undefined,
        qs
      )
    );

    // const unifiedVisitQuery = {
    //   ...unifiedVisitsQueryFromFilters(filters),
    //   withAvailability: true,
    //   offset: schedule.offset && schedule.offset,
    //   pageSize: schedule.pageSize
    // }
    // yield put(schedulingActions.getUnifiedVisitsRequest({
    //     providerId: provider.providerId
    //   },
    //   unifiedVisitQuery
    // ))
    //
    //
    // const planboardQuery = {
    //   ...unifiedVisitsQueryFromFilters(filters),
    //   withAvailability: true,
    //   offset: schedule.offset && schedule.offset,
    //   statuses: ['projected'],
    //   pageSize: 1000
    // }
    // if (planboardQuery.timeTo.diff(planboardQuery.timeFrom, 'days') === 1) {
    //   yield put(actions.getPlanboardRequest({
    //       providerId: provider.providerId
    //     },
    //     planboardQuery
    //   ))
    // }
    //
    // yield put(availabilityActions.getUnifiedAvailabilityRequest({
    //     providerId: provider.providerId
    //   },
    //   unifiedAvailabilityQueryFromFilters(filters)
    // ))
    //
    //
    // yield put(roundsActions.getUnifiedRoundsRequest({
    //     providerId: provider.providerId,
    //   },
    //   {
    //     ...unifiedRoundsQueryFromFilters(filters),
    //     withAvailability: true,
    //     offset: schedule.offset && schedule.offset,
    //     pageSize: schedule.pageSize
    //   }
    // ))
  });
}

function* downloadCSV() {
  yield takeEvery([actions.DOWNLOAD_CSV_REQUEST], function* (action) {
    const state = store.getState();
    const { filters } = state.Rostering;
    const provider = state.AuthUser.provider;

    switch (action.mode) {
      case "scheduling":
        yield put(
          schedulingActions.downloadCsvRequest(
            {
              providerId: provider.providerId,
            },
            unifiedVisitsQueryFromFilters(filters),
            action.successCallback
          )
        );
        break;
    }
  });
}

export function* getPlanboardRequest() {
  yield defineGetSagaProvider(
    actions.ROSTERING_GET_PLANBOARD_REQUEST,
    Api.Scheduling.getUnifiedVisits,
    actions.getPlanboardSuccess,
    actions.getPlanboardError
  );
}

export function* getUnifiedRosterRequest() {
  yield defineGetSagaProvider(
    actions.ROSTERING_GET_UNIFIED_ROSTER_REQUEST,
    Api.Rostering.getUnifiedRoster,
    actions.getUnifiedRosterSuccess,
    actions.getUnifiedRosterError
  );
}

export default function* rootSaga() {
  yield all([
    fork(setFilters),
    fork(load),
    fork(downloadCSV),
    fork(getPlanboardRequest),
    fork(getUnifiedRosterRequest),
  ]);
}
