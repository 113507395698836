import { combineReducers } from "redux";

import { all } from "redux-saga/effects";
import {
  actions as bankActions,
  reducer as bankReducer,
  saga as bankSaga,
} from "./bankDetails";
import {
  actions as addressActions,
  reducer as addressReducer,
  saga as addressSaga,
} from "./address";
import {
  actions as workingTypeActions,
  reducer as workingTypeReducer,
  saga as workingTypeSaga,
} from "./workingType";
import {
  actions as personalDetailsActions,
  reducer as personalDetailsReducer,
  saga as personalDetailsSaga,
} from "./personalDetails";
import {
  actions as nokActions,
  reducer as nokReducer,
  saga as nokSaga,
} from "./nextOfKin";
import {
  actions as legalActions,
  reducer as legalReducer,
  saga as legalSaga,
} from "./legal";
import {
  actions as holidaySummaryActions,
  reducer as holidaySummaryReducer,
  saga as holidaySummarySaga,
} from "./holidaySummary";
import {
  actions as servicesLocationsActions,
  reducer as servicesLocationsReducer,
  saga as servicesLocationsSaga,
} from "./serviceLocations";

export const reducers = combineReducers({
  personalDetails: personalDetailsReducer,
  address: addressReducer,
  bankDetails: bankReducer,
  nextOfKin: nokReducer,
  legal: legalReducer,
  serviceLocations: servicesLocationsReducer,
  holidaySummary: holidaySummaryReducer,
  workingType: workingTypeReducer,
});

export const actions = {
  ...bankActions,
  ...addressActions,
  ...personalDetailsActions,
  ...nokActions,
  ...legalActions,
  ...holidaySummaryActions,
  // ...workingHoursActions,
  ...servicesLocationsActions,
  ...workingTypeActions,
};

export const sagas = function* () {
  yield all([
    bankSaga(),
    addressSaga(),
    personalDetailsSaga(),
    nokSaga(),
    legalSaga(),
    // workingHoursSaga(),
    servicesLocationsSaga(),
    holidaySummarySaga(),
    workingTypeSaga(),
  ]);
};
