import { store } from "../BaseWrapper";
import { palette } from "styled-theme";

export const defaultRadius = 5;

// https://stackoverflow.com/a/13532993
export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export const colorShade = (col, amt) => {
  col = col.replace(/^#/, "");
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
};

export function setExposure(col, exposure) {
  const R = parseInt(col.substring(1, 3), 16);
  const G = parseInt(col.substring(3, 5), 16);
  const B = parseInt(col.substring(5, 7), 16);

  let NR, NG, NB;

  exposure = Math.max(Math.min(exposure, 1.0), -1.0);
  if (exposure >= 0) {
    NR = Math.round(R + (255 - R) * exposure);
    NG = Math.round(G + (255 - G) * exposure);
    NB = Math.round(B + (255 - B) * exposure);
  } else {
    NR = Math.round(R + R * exposure);
    NG = Math.round(G + G * exposure);
    NB = Math.round(B + B * exposure);
  }

  const RO =
    NR.toString(16).length === 1 ? "0" + NR.toString(16) : NR.toString(16);
  const GO =
    NG.toString(16).length === 1 ? "0" + NG.toString(16) : NG.toString(16);
  const BO =
    NB.toString(16).length === 1 ? "0" + NB.toString(16) : NB.toString(16);

  return "#" + RO + GO + BO;
}

// Transition
export function transition(timing = 0.3) {
  return `
      -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;
}

// Border Radius
export function borderRadius(radius = defaultRadius) {
  return `
      -webkit-border-radius: ${radius}px;
      -moz-border-radius: ${radius}px;
      -ms-transition: ${radius}px;
      -o-border-radius: ${radius}px;
      border-radius: ${radius}px;
  `;
}

// Border Radius Top
export function borderRadiusTop(radius = defaultRadius) {
  return `
      -webkit-border-top-left-radius: ${radius}px ;
      -moz-border-radius-topleft: ${radius}px;
      border-top-left-radius: ${radius}px ;

      -webkit-border-top-right-radius: ${radius}px ;
      -moz-border-radius-topright:  ${radius}px;
      border-top-right-radius: ${radius}px;
  `;
}

// Border Radius Bottom
export function borderRadiusBottom(radius = defaultRadius) {
  return `
      -webkit-border-bottom-left-radius: ${radius}px ;
      -moz-border-radius-bottomleft: ${radius}px;
      border-bottom-left-radius: ${radius}px ;

      -webkit-border-bottom-right-radius: ${radius}px ;
      -moz-border-radius-bottomright:  ${radius}px;
      border-bottom-right-radius: ${radius}px;
  `;
}

export function getDefaultRadius() {
  return defaultRadius;
}

// Box Shadow
export function boxShadow(shadow = "none") {
  return `
      -webkit-box-shadow: ${shadow};
      -moz-box-shadow: ${shadow};
      box-shadow: ${shadow};
  `;
}

export const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

// Axela Gradient
export function axelaGradient(paletteWHHAAT, opacity = 1, direction = "left") {
  let otherDirection = "right";
  let startColor = palette("primary", 0)();
  let endColor = palette("primary", 4)();

  // state.Interface.theme.palette.primary[0]
  if (direction === "right") {
    otherDirection = "left";

    // Swap the colors
    const tmpColor = startColor;
    startColor = endColor;
    endColor = tmpColor;
  }

  const startColorRGBA = convertHexToRGBA(startColor, opacity);
  const endColorRGBA = convertHexToRGBA(endColor, opacity);
  // let startColorRGBA = startColor
  // let endColorRGBA = endColor

  // /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2b3259+0,2f95a4+90 */
  // background: #2b3259; /* Old browsers */
  // background: -moz-linear-gradient(top,  #2b3259 0%, #2f95a4 90%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(top,  #2b3259 0%,#2f95a4 90%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(to bottom,  #2b3259 0%,#2f95a4 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b3259', endColorstr='#2f95a4',GradientType=0 ); /* IE6-9 */

  const gradient = `
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2b3259+0,2f95a4+90 */
    background: ${startColor}; /* Old browsers */
    background: -moz-linear-gradient(${direction}, ${startColorRGBA} 0%, ${endColorRGBA} 90%); /* FF3.6-15 */
    background: -webkit-linear-gradient(${direction}, ${startColorRGBA} 0%,${endColorRGBA} 90%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to ${otherDirection}, ${startColorRGBA} 0%,${endColorRGBA} 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${startColor}', endColorstr='${endColor}',GradientType=1 ); /* IE6-9 */
  `;
  return gradient;
}

export function axelaGradientOLD(palette, opacity = 1, direction = "left") {
  const state = store.getState();

  let otherDirection = "right";
  let startColor = palette("primary", 0)();
  let endColor = palette("primary", 4)();

  if (
    state &&
    state.Interface &&
    state.Interface.theme &&
    state.Interface.theme.palette
  ) {
    // console.log(state.Interface.theme.palette)
    startColor = state.Interface.theme.palette.primary[4];
    endColor = state.Interface.theme.palette.primary[0];
  }

  // state.Interface.theme.palette.primary[0]
  if (direction === "right") {
    otherDirection = "left";

    // Swap the colors
    const tmpColor = startColor;
    startColor = endColor;
    endColor = tmpColor;
  }

  const startColorRGBA = convertHexToRGBA(startColor, opacity);
  const endColorRGBA = convertHexToRGBA(endColor, opacity);
  // let startColorRGBA = startColor
  // let endColorRGBA = endColor

  // /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2b3259+0,2f95a4+90 */
  // background: #2b3259; /* Old browsers */
  // background: -moz-linear-gradient(top,  #2b3259 0%, #2f95a4 90%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(top,  #2b3259 0%,#2f95a4 90%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(to bottom,  #2b3259 0%,#2f95a4 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b3259', endColorstr='#2f95a4',GradientType=0 ); /* IE6-9 */

  const gradient = `
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2b3259+0,2f95a4+90 */
    background: ${startColor}; /* Old browsers */
    background: -moz-linear-gradient(${direction}, ${startColorRGBA} 0%, ${endColorRGBA} 90%); /* FF3.6-15 */
    background: -webkit-linear-gradient(${direction}, ${startColorRGBA} 0%,${endColorRGBA} 90%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to ${otherDirection}, ${startColorRGBA} 0%,${endColorRGBA} 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${startColor}', endColorstr='${endColor}',GradientType=1 ); /* IE6-9 */
  `;
  // console.log(gradient)
  return gradient;
}
