import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getAuthToken } from "../../../helpers/tokens";
import _ from "lodash";

export function* getList(actions, ApiClient, apiPath) {
  yield takeEvery(actions.GET_LIST_REQUEST, function* (action) {
    const { payload, successCallback, errorCallback, qs } = action;
    const authToken = yield getAuthToken();
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        ApiClient.getList,
        authToken.token,
        payload,
        apiPath,
        qs
      );
      if (response) {
        yield put(actions.getListSuccess(response, payload));
        _.isFunction(successCallback) && successCallback(response, payload);
      } else {
        yield put(actions.getListError(error, payload));
        _.isFunction(errorCallback) && errorCallback(error, payload);
      }
    }
  });
}

export function* getRequest(actions, ApiClient, apiPath, propIdName) {
  yield takeEvery(actions.GET_REQUEST, function* (action) {
    const { payload, successCallback, errorCallback } = action;
    const authToken = yield getAuthToken();
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        ApiClient.get,
        authToken.token,
        payload,
        apiPath,
        propIdName
      );
      if (response) {
        yield put(actions.getSuccess(response, payload));
        _.isFunction(successCallback) && successCallback(response, payload);
      } else {
        yield put(actions.getError(error, payload));
        _.isFunction(errorCallback) && errorCallback(error, payload);
      }
    }
  });
}

export function* createRequest(actions, ApiClient, apiPath) {
  yield takeEvery(actions.CREATE_REQUEST, function* (action) {
    const { payload, successCallback, errorCallback } = action;
    const authToken = yield getAuthToken();
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        ApiClient.create,
        authToken.token,
        payload.data,
        apiPath
      );
      if (response) {
        yield put(actions.createSuccess(response, payload));
        _.isFunction(successCallback) && successCallback(response, payload);
        // This assumes the response should have the id of the parent in it.
        yield put(actions.getList(payload.data));
      } else {
        yield put(actions.createError(error, payload));
        _.isFunction(errorCallback) && errorCallback(error, payload);
      }
    }
  });
}

export function* updateRequest(actions, ApiClient, apiPath, propIdName) {
  yield takeEvery(actions.UPDATE_REQUEST, function* (action) {
    const { payload, successCallback, errorCallback } = action;
    const authToken = yield getAuthToken();
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        ApiClient.update,
        authToken.token,
        payload.data,
        apiPath,
        propIdName
      );
      if (response) {
        yield put(actions.updateSuccess(response, payload));
        _.isFunction(successCallback) && successCallback(response, payload);
      } else {
        yield put(actions.updateError(error, payload));
        _.isFunction(errorCallback) && errorCallback(error, payload);
      }
      yield put(actions.getList(payload.data));
    }
  });
}

export function* deleteRequest(actions, ApiClient, apiPath, propIdName) {
  yield takeEvery(actions.DELETE_REQUEST, function* (action) {
    const { payload, successCallback, errorCallback } = action;
    const authToken = yield getAuthToken();
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        ApiClient.delete,
        authToken.token,
        payload.data,
        apiPath,
        propIdName
      );
      if (response) {
        // Pass the original request payload back through the success/error action to allow follow up actions with the same input data
        yield put(actions.deleteSuccess(response, payload));
        _.isFunction(successCallback) && successCallback(response, payload);
      } else {
        yield put(actions.deleteError(error, payload));
        _.isFunction(errorCallback) && errorCallback(error, payload);
      }
      yield put(actions.getList(payload.data));
    }
  });
}

export default function* rootSaga(actions, ApiClient, apiPath, propIdName) {
  yield all([
    fork(getList, ...[actions, ApiClient, apiPath]),
    fork(getRequest, ...[actions, ApiClient, apiPath, propIdName]),
    fork(createRequest, ...[actions, ApiClient, apiPath]),
    fork(updateRequest, ...[actions, ApiClient, apiPath, propIdName]),
    fork(deleteRequest, ...[actions, ApiClient, apiPath, propIdName]),
  ]);
}
