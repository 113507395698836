import config from "./settings";
import {
  commonDelete,
  commonGet,
  commonPost,
  commonPut,
  substituteVariables,
} from "./common";

class Crud {
  // Searches an endpoint url for variables starting with ":"
  // Replaces the variable with the equivalently named property in "data"
  // e.g. "/providers/:providerId/user/:userId" would be changed to "/providers/xxx/user/yyy"
  // where data = {providerId: "xxx", userId: "yyy"}

  static getList(token, data, apiPath, qs = undefined) {
    const endpoint = substituteVariables(`${apiPath}`, data);
    const url = `${config.url}/auth/${endpoint}` + (qs ? "?" + qs : "");
    return commonGet(token, url);
  }

  static get(token, data, apiPath, propNameId) {
    const apiPathParts = apiPath.split("?");
    const endpoint =
      substituteVariables(`${apiPathParts[0]}/:${propNameId}`, data) +
      (apiPathParts[1] ? "?" + apiPathParts[1] : "");
    const url = `${config.url}/auth/${endpoint}`;
    return commonGet(token, url);
  }

  static create(token, data, apiPath) {
    const endpoint = substituteVariables(`${apiPath}`, data);
    const url = `${config.url}/auth/${endpoint}`;
    return commonPost(token, url, data);
  }

  static update(token, data, apiPath, propNameId) {
    const apiPathParts = apiPath.split("?");
    const endpoint =
      substituteVariables(`${apiPathParts[0]}/:${propNameId}`, data) +
      (apiPathParts[1] ? "?" + apiPathParts[1] : "");
    const url = `${config.url}/auth/${endpoint}`;
    return commonPut(token, url, data);
  }

  static delete(token, data, apiPath, propNameId) {
    const apiPathParts = apiPath.split("?");
    const endpoint =
      substituteVariables(`${apiPathParts[0]}/:${propNameId}`, data) +
      (apiPathParts[1] ? "?" + apiPathParts[1] : "");
    const url = `${config.url}/auth/${endpoint}`;
    return commonDelete(token, url, data);
  }
}

export default Crud;
