import config from "./settings";
import { commonDelete, commonGetBlob } from "./common";

class CommunicationMessages {
  static downloadCsv(token, providerId, userId, data) {
    const { messageType, dateFrom, dateTo } = data;
    const qs =
      `communicationMessageType=${messageType || ""}` +
      `&dateFrom=${dateFrom || ""}` +
      `&dateTo=${dateTo || ""}` +
      `&includePrivate=true`;

    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/communicationmessages/csv?${qs}`;
    // console.log(url);
    return commonGetBlob(token, url);
  }

  static deleteCommunicationMessage(token, providerId, userId, messageId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/communicationmessages/${messageId}`;
    return commonDelete(token, url);
  }
}

export default CommunicationMessages;
