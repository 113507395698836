import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/app/providers/actions";
import Spin from "../../components/styled/spin";
import { actions as providerServicesActions } from "../../redux/app/serviceslocations/crudServices";
import _ from "lodash";

export const AuthContext = React.createContext();

export default function AuthContextProvider(props) {
  const dispatch = useDispatch();
  const { user, provider, services } = useSelector((state) => state.AuthUser);
  const providerSettings = useSelector(
    (state) => state.Providers.settings.data
  );
  const providerServices = useSelector(
    (state) => state.ServicesLocations.services
  );
  const roles = useSelector((state) => state.Providers.roles.data);
  const [userRoles, setUserRoles] = useState(null);
  const [staffRoles, setStaffRoles] = useState(null);
  const [locationMap, setLocationMap] = useState({});

  useEffect(() => {
    if (!user || !user.providerId) return;

    dispatch(actions.getProviderSettingsRequest(user.providerId));

    dispatch(providerServicesActions.getList({ providerId: user.providerId }));

    dispatch(actions.getProviderRolesRequest({ providerId: user.providerId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setUserRoles(
      _.values(roles)
        .filter((role) => !role["isBot"])
        .map((role) => {
          return role.role;
        })
    );
    setStaffRoles(
      _.values(roles)
        .filter((role) => !role["isBot"] && !role["isServiceUser"])
        .map((role) => {
          return role.role;
        })
    );
  }, [roles]);

  useEffect(() => {
    if (!providerServices.listLoaded) {
      return;
    }

    // Reduce the providerServices to a map of location IDs to names
    setLocationMap(
      _.reduce(
        providerServices.list,
        (result, value) => {
          return _.reduce(
            value.locations,
            (subResult, subValue, subKey) => {
              subResult[subKey] = subValue;
              return subResult;
            },
            result
          );
        },
        {}
      )
    );
  }, [providerServices]);

  if (
    !user ||
    !provider ||
    !services ||
    !services.loaded ||
    !providerServices.listLoaded ||
    !providerSettings ||
    _.isEmpty(locationMap)
  ) {
    return (
      <div className={"authContextLoading"}>
        <Spin />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        authUser: user,
        authProvider: provider,
        authServices: services,
        providerServices: providerServices,
        providerLocations: locationMap,
        providerSettings: providerSettings,
        providerRoles: roles,
        userRoles: userRoles,
        staffRoles: staffRoles,
      }}
    >
      {user &&
        provider &&
        roles &&
        userRoles &&
        staffRoles &&
        services &&
        providerServices &&
        providerSettings &&
        props.children}
    </AuthContext.Provider>
  );
}
